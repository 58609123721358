<template>
  <div class="map-div" id="mapDiv" ref="mapDiv"></div>
  <!-- <el-button size="mini" @click="showVideo(item.pkTaskId)">现场视频</el-button>
            <el-button size="mini" @click="showModel(item.pkTaskId)" style="margin-top: 10px">三维模型</el-button> -->
</template>

<script>
import Vue from 'vue'
// import { arrayStringToArray } from '../utils/transform'
// import { findVideoMessage } from "@/api/videoHaLuoYu";
// import { findProjectSpaceCoordinatesByProjectId } from "@/api/projectSpaceCoordinates";
// http://47.107.75.175:18000/static/img/snap.0ea3828d.png
// noPhotoWithMes
// clearOverLays
// let noImg =
const MyInfoWindow = Vue.extend({
  template: `
      <div
      id="tipsMap"
        style="position: absolute;
      width: 500px;
      min-height: 200px;
      bottom: 20px;
      left: 0;
      background-color: #fff;
      box-shadow: 0 3px 14px rgba(0,0,0,.4);
      cursor: default">
        <div style="float: left">
          <div  style="padding: 20px 10px 0 20px; width: 210px; line-height: 24px;
    font-size: 14px ">
            <div>项目名称： {{ item.projectName  }}</div>
            <div>工程编号： {{ item.projectNo }}</div>
            <div>运行状态： {{ item.runningState }}</div>
            <div>工程地址： {{ item.projectAddress }}</div>
            <div>安全状态： {{ item.fkSafeStateId | alarm}}</div>
          </div>
          <div style="padding: 20px 20px 20px 5px; font-size: 14px; width: 210px; display: block;">
            <el-button size="mini" @click="showDetail" style="margin-left: 10px">监测详情</el-button>
           
          </div>
        </div>
        <div style="float: right; width: 250px; height: 250px;">
          <img style="height: 230px;
               width: 230px;
               margin: 10px 0 0 0;
               background-size: 230px 230px;"
               :data-src="item.flatPatternmakingOne || item.flatPatternmakingTwo || item.plan || 'http://120.79.62.199:9090/api/images/project_images/20210907094054_noPhotoWithMes.png' "
               :alt="item.projectName"
          />
        </div>
      </div>`,
  props: {
    item: {
      type: Object,
    },
    href: {
      type: String,
    },
  },
  data() {
    return {
      location: null,
    }
  },
  filters: {
    alarm(e) {
      let a = ''
      switch (e) {
        case 1:
          a = '安全'
          break
        case 2:
          a = '预警'
          break
        case 3:
          a = '报警'
          break
      }
      return a
    },
  },
  methods: {
    showDetail() {
      // const { href } = this.$router.resolve({
      //   name: 'PMCondition',
      //   params: {
      //     projectId: this.item.projectId,
      //   },
      // })
      // window.open(href, '_blank')
      window.open('/ProjectMonitor/' + (this.item.pkTaskId - 0) + '/Condition')
    },
    showVideo(projectId) {
      console.log(projectId)
      // findVideoMessage(projectId).then((res) => {
      //     const data = res.data.data;
      //     if (data) {
      //         window.open(
      //             `http://${data.ipAddress}:${data.webPort}/zyrh.aspx?menucode=10021&user=${data.videoAccount}&pwd=${data.videoDevicePassword}&lineId=1&autoPlay=0`
      //         );
      //     } else {
      //         this.$alert("此工程暂未配置视频信息");
      //     }
      // });
    },
    showModel(projectId) {
      console.log(projectId)
      // findProjectSpaceCoordinatesByProjectId(projectId).then((res) => {
      //     const { data } = res.data;
      //     if (
      //         data.height === null ||
      //         data.latitude === null ||
      //         data.longitude === null
      //     ) {
      //         this.$alert("此工程暂未配置三维信息");
      //     } else {
      //         // 不用env来控制 而是通过 判断url中是否包含localhost来判断跳转的链接
      //         // 当然这里在开发环境的时候有可能会打开的为生产环境的三维 使用localhost本地服务就能避开这个问题
      //         // const href = window.location.href.indexOf('localhost') !== -1 ? `http://localhost:8014/${projectId}` : `http://120.79.62.199:8014/${projectId}`
      //         window.open(
      //             `${window.location.protocol}//${window.location.hostname}:8014/${projectId}`
      //         );
      //     }
      // });
    },
  },
})
const labels = new Map()
export default {
  props: {
    // 展示型地图传入的数据列表
    allData: Array,
    // 判断是展示型地图还是添加点型地图
    addMarkerFlag: {
      type: Boolean,
      default: false,
    },
    lng: {
      type: Number,
      default: 113.27,
    },
    lat: {
      type: Number,
      default: 23.13,
    },
    lineTool: {
      type: Number,
    },
    planeTool: {
      type: Number,
    },
    rectangleTool: {
      type: Number,
    },
    circleTool: {
      type: Number,
    },
  },
  data() {
    return {
      PROJECTION: 'EPSG:4326',
      ZOOM: 7,
      LABELVISIBLEZOOM: 13,
      map: null,

      // mapTypes
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (!window.T) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        // script.async = true
        script.src = 'https://api.tianditu.gov.cn/api?v=4.0&tk=d313f2f224afe3f8b909d341f713b7f0'
        document.body.append(script)

        script.onload = this.initMap
      } else {
        this.initMap()
      }
    },
    initMap() {
      const T = window.T
      const mapDiv = document.getElementById('mapDiv')
      const map = (this.map = new T.Map(mapDiv, {
        datasourcesControl: true,
        projection: this.PROJECTION,
        center: new T.LngLat(this.lng || 113.27, this.lat || 23.13),
        zoom: this.ZOOM,
      }))

      map.addControl(new T.Control.MapType())
      map.setMapType(window.TMAP_HYBRID_MAP)
      map.disableDoubleClickZoom()

      if (this.addMarkerFlag) {
        console.log('添加点')
        this.addMarker({ longitude: this.lng, latitude: this.lat })
        map.addEventListener('dblclick', ({ lnglat }) => {
          let lng = lnglat.lng
          let lat = lnglat.lat
          this.clearOverLay()
          this.addMarker({ longitude: lng, latitude: lat })
          this.$emit('addMarkerSuccess', lng, lat)
        })
      } else {
        // 如果是显示点的类型就遍历将点加上去
        console.log('显示点')
        if (Array.isArray(this.allData)) {
          this.allData.forEach((item) => {
            // console.log('item', item)
            this.addMarker(item)
          })
        }
      }
    },

    addMarker(item) {
      if (!item || !item.longitude || !item.latitude) {
        // console.log('item', item)
        return
      }
      if (!window.T) {
        // window.T 未加载时会报错
        return
      }
      const { LngLat, Icon, Point, Marker, InfoWindow } = window.T

      const lngLat = new LngLat(item.longitude ? item.longitude : 0, item.latitude ? item.latitude : 0)

      let icon = null

      // 获取图标
      const iconSize = new Point(27, 25)
      const iconAnchor = new Point(10, 25)
      switch (item.fkSafeStateId) {
        case 2:
          icon = new Icon({
            iconUrl: require('../assets/image/marker_level2_sprite.png'),
            iconSize,
            iconAnchor,
          })
          break

        case 3:
          icon = new Icon({
            iconUrl: require('../assets/image/marker_level4_sprite.png'),
            iconSize,
            iconAnchor,
          })
          break
        default:
          icon = new Icon({
            iconUrl: require('../assets/image/marker_level0_sprite.png'),
            iconSize,
            iconAnchor,
          })
          break
      }

      // 新建一个坐标点并添加到地图上
      const marker = new Marker(lngLat, { icon })

      this.map.addOverLay(marker)

      // 每增加一个marker，就new一个组件构造器对象，作为infoWindow，并通过propsData的方式传递数据到组件prop中
      //   console.log(this.addMarkerFlag)
      if (!this.addMarkerFlag) {
        const component = new MyInfoWindow({
          propsData: {
            item: item,
            href: this.href,
          },
        }).$mount()
        const windowInfo = new InfoWindow(component.$el, {
          closeOnClick: true,
        })

        // 为每一个marker注册一个点击事件 点击就会弹出上面注册的infoWindow
        marker.addEventListener('click', function () {
          // ;
          marker.openInfoWindow(windowInfo)
          const imageDom = windowInfo.UT && windowInfo.UT.lastChild && windowInfo.UT.lastChild.lastChild && windowInfo.UT.lastChild.lastChild.lastChild && windowInfo.UT.lastChild.lastChild.lastChild.lastChild
          const dataSrc = imageDom.getAttribute('data-src')
          const src = imageDom.getAttribute('src')
          if (dataSrc && !src) {
            imageDom.setAttribute('src', dataSrc)
          }
        })

        // 为当前点添加label 鼠标移上去显示 放下消失
        this.addLabel(item.longitude, item.latitude, item.projectName, item.projectId)
        marker.addEventListener('mouseover', () => {
          // FIXME 设置index和三维的z轴都没办法改变在最地图底层的命运
          // e.originalEvent.target.style.zIndex = 9999
          if (!this.labelVisible) {
            this.addLabel(item.longitude, item.latitude, item.projectName, item.projectId)
          }
        })
        marker.addEventListener('mouseout', () => {
          if (!this.labelVisible) {
            this.removeLabel(item.projectId)
          }
        })
      }
    },

    addLabel(lng, lat, message, id) {
      if (labels.has(id)) {
        this.map.addOverLay(labels.get(id))
        return
      }

      // 仅有在切换显示的时候不会传入任何参数(id)
      if (id !== undefined) {
        const { LngLat, Label, Point } = window.T
        console.log('LngLat', LngLat)
        const lnglat = new LngLat(lng, lat)

        let label = null
        label = new Label({
          text: `<div>${message}</div>`,
          position: lnglat,
          offset: new Point(5, -12),
        })
        label.setZindex(9990)
        label.setBackgroundColor('#ffba20')
        label.setFontColor('#fff')
        label.setBorderLine(0)

        // 将新建的label传入labels哈希表中
        labels.set(id, label)
      } else {
        // 如果只是切换显示，直接遍历labels 然后添加到地图中
        for (let label of labels.values()) {
          this.map.addOverLay(label)
        }
      }
    },
    // 删除label
    removeLabel(id) {
      // 如果包含id就删除该id
      if (id !== undefined && labels.has(id)) {
        this.map.removeOverLay(labels.get(id))
      } else {
        // 否则清空所有
        for (let label of labels.values()) {
          this.map.removeOverLay(label)
        }
      }
    },
    // 改变地图中心点
    changeMapCenter(lng, lat) {
      const T = window.T
      if (this.map && this.lng && this.lat && T) {
        this.map.panTo(new T.LngLat(lng, lat), this.ZOOM)
      }
    },
    // 重置overlay 先清空地图上所有信息 然后再将allData的只遍历添加
    resetOverLay() {
      this.clearOverLay()

      if (this.allData && this.allData.length > 0) {
        // console.log('this.allData 没', this.allData)

        this.allData.forEach((item) => {
          // console.log('item_resetOverLay', item)
          this.addMarker(item)
        })
      }
    },
    // 清空地图上所有的overLay 然后再清空哈希表
    clearOverLay() {
      // console.log(this.map)
      this.map ? this.map.clearOverLays() : null
      // this.map.clearOverLays()
      labels.clear()
    },
  },

  watch: {
    keyword: {
      handler() {
        this.zoomArr = []
        this.priorityCitys = []
      },
    },
    allData: {
      // immediate: false,
      handler() {
        this.changeMapCenter(this.lng, this.lat)
        this.resetOverLay()
      },
      deep: true,
    },
    lineTool: {
      handler() {
        let mapLineTool = new window.T.PolylineTool(this.map, {
          showLabel: true,
        })
        mapLineTool.open()
      },
    },
    planeTool: {
      handler() {
        let polygonTool = new window.T.PolygonTool(this.map, {
          showLabel: true,
          color: 'blue',
          weight: 3,
          opacity: 0.5,
          fillColor: '#FFFFFF',
          fillOpacity: 0.5,
        })
        polygonTool.open()
      },
    },
    rectangleTool: {
      handler() {
        let rectTool = new window.T.RectangleTool(this.map)
        rectTool.open()
      },
    },
    circleTool: {
      handler() {
        let circleTool = new window.T.CircleTool(this.map, {
          color: 'blue',
          weight: 3,
          opacity: 0.5,
          fillColor: '#FFFFFF',
          fillOpacity: 0.5,
        })
        circleTool.open()
      },
    },
  },
}
</script>

<style scoped lang="scss">
.map-div {
  width: 100%;
  height: 100%;
}

.itemText {
  >div {
    line-height: 40px;
    font-size: 16px !important;
  }
}
</style>
